.dashboard-index {
  padding: 20px 10px;
  &-card {
    min-height: 300px;
    border-radius: 8px;
    background: $light-blue-2;
    h2 {
      color: $grey-6;
    }
    p {
      color: $grey-8;
    }
    h6 {
      color: #3b82f6;
    }
  }
}

.payroll {
  &-bread-crumb {
    font-size: 14px;
    &-active {
      p {
        span {
          color: $main-blue;
          svg {
            path {
              fill: $main-blue;
            }
          }
        }
      }
    }

    &-arrow {
      svg {
        rotate: 90deg;
        fill: $grey-2;
        width: 8px;
      }
    }
  }

  &-setup {
    button {
      border-radius: 2px;
      border: 1px solid #93c5fd;
      background: $blue-transparent;
    }
  }

  &-tabs {
    &-list {
      li {
        padding: 3px 0;
        margin-right: 16px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: all 0.3s ease-in-out;
      }
      li.active {
        border-bottom: 2px solid $main-blue;
        background-color: transparent !important;
        color: $main-blue !important;
      }
    }
  }
}

.payroll-overlay {
  content: "";
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: #000000, $alpha: 0.2);
  inset: 0;
  z-index: 10000;
  &-button {
    background-color: $light-blue-1;
    width: max-content;
    border-radius: 3px;
    button {
      border-radius: 2px;
      border: 1px solid #93c5fd;
      background: $blue-transparent;
      width: max-content;
    }
  }

  &-info {
    border-radius: 4px;
    button.set {
      box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
    }
  }
}

.select_simple {
  background-color: red !important;
  option {
    background-color: #ffffff;
  }
}

.payroll-setup-modal {
  &-form {
    label {
      color: #1e293b;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.25px;
    }
    select {
      width: 100%;
      outline: none;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1.2px solid $grey-3;
      color: #475569;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.1px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #ffffff !important;
    }

    p.info {
      color: #77777a;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
  &-overtime-policy {
    &-toggle {
      /* TOOGLE COMPONENT STYLE STARTS  */
      .toggle {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 28px;
      }

      .toggle input {
        display: none;
      }

      .toggle-slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* background-color: #ccc; */
        background-color: #d8d8d8;
        border-radius: 28px;
        transition: 0.4s;
      }

      -slider:before {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 50%;
        left: 2px;
        top: 2px;
        transition: 0.4s;
      }

      .toggle input:checked + .toggle-slider {
        background-color: $main-blue;
      }

      .toggle input:checked + .toggle-slider:before {
        transform: translateX(22px);
      }

      /* TOOGLE COMPONENT STYLE ENDS  */
    }
  }
}

.payroll-modal-setup {
  &-form {
    label {
      color: #1e293b;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.25px;
    }
    select {
      width: 100%;
      outline: none;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1.2px solid $grey-3;
      color: #475569;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.1px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #ffffff !important;
    }

    p.info {
      color: #77777a;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }

  &-overtime-policy {
    &-toggle-disable {
      /* TOOGLE COMPONENT STYLE STARTS  */
      .toggle-disable {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 28px;
      }

      .toggle-disable input {
        display: none;
      }

      .toggle-slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* background-color: #ccc; */
        background-color: #d8d8d8;
        border-radius: 28px;
        transition: 0.4s;
      }

      .toggle-slider:before {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 50%;
        left: 2px;
        top: 2px;
        transition: 0.4s;
      }

      .toggle-disable input:checked + .toggle-slider {
        background-color: #93c5fd;
      }

      .toggle-disable input:checked + .toggle-slider:before {
        transform: translateX(22px);
      }

      /* TOOGLE COMPONENT STYLE ENDS  */
    }
  }
}

.payroll-divider {
  height: 1px;
  width: 100%;
  background-color: #e2e8f0;
}
