@import "./form";
@import "./components";
@import "./modal";

@media (min-width: 1560px) {
  .section-container {
    max-width: 1520px;
  }
}

.section-container {
  padding: 0 3rem;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .section-container {
    padding: 0 3rem;
  }
}

@media (max-width: 767px) {
  .section-container {
    padding: 0 2rem;
  }
}

@media (max-width: 567px) {
  .section-container {
    padding: 0 1rem;
  }
}

.auth-container {
  // padding: 2.5rem 0;
  background: #f1f5f9;
  &-box-one {
    // height: calc(100vh - 5rem);
    height: auto;
    min-height: calc(100vh - 2.5rem);
    background: #334155;
    padding: 31px 37px;
    .auth-logo-box {
      img {
        width: 120px;
      }
    }

    .auth-content-box {
      color: #f1f5f9;
      &-header {
        text-align: center;
        margin-bottom: 42px;
        h3 {
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 127.273%;
          margin-bottom: 7px;
        }
        h6 {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 142.857%;
          letter-spacing: 0.1px;
          padding-left: 22px;
          position: relative;
          width: max-content;
          margin: 0 auto;
          &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 4px;
            background-color: #f1f5f9;
            top: calc(50% - 2px);
            left: 0;
            border-radius: 4px;
          }
        }
      }
      &-list {
        p {
          color: #fff;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 145.455%;
          letter-spacing: 0.5px;
          margin-bottom: 16px !important;
        }

        &-item {
          img {
            width: 22px;
            height: 22px;
          }
          p {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
            letter-spacing: 0.25px;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  &-box-two {
    background: #fff;
    padding: 20px;
    // height: calc(100vh - 5rem);
    min-height: calc(100vh - 2.5rem);
    height: auto;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #5b6779;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #334155 #f1f1f1;
    &-step-box {
      h6 {
        color: #334155;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 142.857%;
        letter-spacing: 0.1px;
      }

      div {
        span {
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #dbeafe;
        }

        span.active {
          background-color: #3b82f6;
        }
      }
    }
    &-content {
      &-header {
        margin-bottom: 56px;
        h4 {
          color: #334155;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 127.273%;
        }

        p {
          color: #64748b;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 133.333%;
          letter-spacing: 0.2px;
        }
      }

      .add-user-btn {
        background-color: transparent;
        border: none;
        outline: none;
        .icon {
          svg {
            width: 20px;
            height: 20px;
          }
        }
        .text {
          color: #2563eb;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 166.667%;
          letter-spacing: 0.1px;
        }
      }

      &-body {
        form {
          .terms-and-condition {
            p {
              color: #334155;
              font-size: 12.5px;
              font-style: normal;
              font-weight: 500;
              line-height: 133.333%;
              letter-spacing: 0.5px;

              a {
                color: #3b82f6;
              }
            }
          }

          .more-info {
            p {
              color: #3b82f6;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 133.333%;
              letter-spacing: 0.5px;
              margin-top: 4px;
            }
          }
          .submit-btn {
            button {
              width: 100%;
              outline: none;
              border-radius: 4px;
              background: #2563eb;
              border: 1px solid #2563eb;
              box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 142.857%;
              letter-spacing: 0.1px;
              padding: 10px;
            }

            p {
              span.icon {
                width: 12px;
                height: 12px;
              }

              span.text {
                color: #2563eb;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 142.857%;
                letter-spacing: 0.1px;
              }
            }
          }
        }

        .divider {
          position: relative;
          p {
            color: #334155;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 142.857%;
            letter-spacing: 0.1px;
          }
        }

        .external-login {
          h6 {
            color: #334155;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.15px;
            margin-bottom: 24px;
          }

          div {
            margin-bottom: 32px;
            span {
              padding: 5px 6px;
              border-radius: 8px;
              border: 1px solid #e2e8f0;
              width: 36px;
              height: 36px;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                width: 24px;
                height: 24px;
              }
            }
          }

          p {
            color: #334155;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 133.333%;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .auth-container {
    background: #f1f5f9;
    &-box-one {
      height: calc(100vh - 5rem);
      background: #334155;
      padding: 31px 37px;
      .auth-logo-box {
        img {
          width: 120px;
        }
      }

      .auth-content-box {
        color: #f1f5f9;
        &-header {
          text-align: center;
          margin-bottom: 42px;
          h3 {
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 127.273%;
            margin-bottom: 7px;
          }
          h6 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 142.857%;
            letter-spacing: 0.1px;
            padding-left: 22px;
            position: relative;
            width: max-content;
            margin: 0 auto;
            &::after {
              content: "";
              position: absolute;
              width: 16px;
              height: 4px;
              background-color: #f1f5f9;
              top: calc(50% - 2px);
              left: 0;
              border-radius: 4px;
            }
          }
        }
        &-list {
          p {
            color: #fff;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 145.455%;
            letter-spacing: 0.5px;
            margin-bottom: 16px !important;
          }

          &-item {
            img {
              width: 22px;
              height: 22px;
            }
            p {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 142.857%;
              letter-spacing: 0.25px;
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }

    &-box-two {
      background: #fff;
      padding: 20px;
      height: auto;
      min-height: 100vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #5b6779;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: #334155 #f1f1f1;
      &-step-box {
        h6 {
          color: #334155;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 142.857%;
          letter-spacing: 0.1px;
        }

        div {
          span {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #dbeafe;
          }

          span.active {
            background-color: #3b82f6;
          }
        }
      }
      &-content {
        &-header {
          margin-bottom: 36px;
          h4 {
            color: #334155;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 127.273%;
          }

          p {
            color: #64748b;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 133.333%;
            letter-spacing: 0.2px;
          }
        }

        .add-user-btn {
          background-color: transparent;
          border: none;
          outline: none;
          .icon {
            svg {
              width: 20px;
              height: 20px;
            }
          }
          .text {
            color: #2563eb;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 166.667%;
            letter-spacing: 0.1px;
          }
        }

        &-body {
          form {
            .terms-and-condition {
              p {
                color: #334155;
                font-size: 12.5px;
                font-style: normal;
                font-weight: 500;
                line-height: 133.333%;
                letter-spacing: 0.5px;

                a {
                  color: #3b82f6;
                }
              }
            }

            .more-info {
              p {
                color: #3b82f6;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 133.333%;
                letter-spacing: 0.5px;
                margin-top: 4px;
              }
            }
            .submit-btn {
              button {
                width: 100%;
                outline: none;
                border-radius: 4px;
                background: #2563eb;
                border: 1px solid #2563eb;
                box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 142.857%;
                letter-spacing: 0.1px;
                padding: 10px;
              }

              p {
                span.icon {
                  width: 12px;
                  height: 12px;
                }

                span.text {
                  color: #2563eb;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 142.857%;
                  letter-spacing: 0.1px;
                }
              }
            }
          }

          .divider {
            position: relative;
            p {
              color: #334155;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 142.857%;
              letter-spacing: 0.1px;
            }
          }

          .external-login {
            h6 {
              color: #334155;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              letter-spacing: 0.15px;
              margin-bottom: 24px;
            }

            div {
              margin-bottom: 32px;
              span {
                padding: 5px 6px;
                border-radius: 8px;
                border: 1px solid #e2e8f0;
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                  width: 24px;
                  height: 24px;
                }
              }
            }

            p {
              color: #334155;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 133.333%;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }
}

.second-auth-container {
  // padding: 4rem 0;
  background-color: #f1f5f9;
  min-height: 100vh;
  width: 100vw;

  &-box {
    // background-color: #fff;
    min-height: 450px;
    border-radius: 4px;
    &-header {
      .logo-box {
        margin-bottom: 8px;
      }
      h5 {
        color: #334155;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 127.273%;
      }
      p {
        color: #64748b;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 133.333%;
        letter-spacing: 0.5px;
      }
    }

    &-body {
      form {
        .forgot-password {
          a {
            text-decoration: none;
            p {
              color: #3b82f6;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 133.333%;
              letter-spacing: 0.5px;
              margin-bottom: 4px;
            }
          }
        }
        .submit-btn {
          button {
            width: 100%;
            outline: none;
            border-radius: 4px;
            background: #2563eb;
            border: 1px solid #2563eb;
            box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 142.857%;
            letter-spacing: 0.1px;
            padding: 10px;
          }

          p {
            span.icon {
              width: 12px;
              height: 12px;
            }

            span.text {
              color: #2563eb;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 142.857%;
              letter-spacing: 0.1px;
            }
          }
        }
      }

      &-footer {
        a {
          text-decoration: none;
          p {
            color: #3b82f6;
            font-size: 12.5px;
            font-style: normal;
            font-weight: 500;
            line-height: 133.333%;
            letter-spacing: 0.5px;
          }
        }
      }

      .external-login {
        margin-top: 28px;
        span.line {
          height: 0.2px;
          background-color: #e2e8f0;
          padding: 0 !important;
        }
        h6 {
          color: #334155;
          // margin-bottom: 24px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 142.857%;
          letter-spacing: 0.1px;
        }

        div {
          margin-bottom: 28px;
          span {
            padding: 5px 6px;
            border-radius: 8px;
            border: 1px solid #e2e8f0;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        p {
          color: #334155;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 133.333%;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}

.add-user-btn {
  background-color: transparent;
  border: none;
  outline: none;
  .icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .text {
    color: #2563eb;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 166.667%;
    letter-spacing: 0.1px;
  }
}

button.small-add-csv {
  padding: 4px 10px;
  border-radius: 4px;
  background: #f8fafc;
  outline: none;
  border: none;
  gap: 4px;
  .text {
    color: #334155;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 153.846%;
    letter-spacing: 0.1px;
  }

  .icon {
    img {
      width: 20px;
      height: 20px;
    }
  }
}
