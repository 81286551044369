button.main-btn {
  width: 100%;
  outline: none;
  border-radius: 4px;
  background: #2563eb;
  border: 1px solid #2563eb;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding: 10px;
}

.input-box ~ p {
  color: #23222d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 10px;
}

.input-box.error ~ p {
  color: #f43f5e;
}

.input-box.filled ~ p {
  color: #2563eb;
}

.input-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}

.input-date-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.input-date-box input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 142.857% */
  letter-spacing: 0.1px;
  outline: none;
}
.input-date-box label {
  position: absolute;
  bottom: 5px;
  left: 14px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.input-date-box label .label-text {
  position: absolute;
  bottom: 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
  transform: translateY(-140%);
  left: 0px;
  background-color: #fff;
  padding: 0 3px 0;
}
.input-date-box label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.input-date-box.error input {
  border: 1px solid #f43f5e;
  color: #f43f5e;
}
.input-date-box.error label .label-text {
  color: #f43f5e;
}

.input-date-box.filled input {
  border: 1px solid #2563eb;
}
.input-date-box.filled label .label-text {
  color: #2563eb;
}

.input-password-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.input-password-box input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 30px 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 142.857% */
  letter-spacing: 0.1px;
  outline: none;
}
.input-password-box .view {
  position: absolute;
  padding: 17px;
  right: 1px;
}
.input-password-box .view svg {
  fill: #64748b;
}
.input-password-box label {
  position: absolute;
  top: 5px;
  left: 14px;
  width: 100%;
  height: 30px;
  pointer-events: none;
}
.input-password-box label .label-text {
  position: absolute;
  top: 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
  transform: translateY(-20px);
  left: 0px;
  background-color: #fff;
  padding: 0 5px 0;
}

.input-textarea-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.input-textarea-box textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 142.857% */
  letter-spacing: 0.1px;
  outline: none;
}
.input-textarea-box label {
  position: absolute;
  top: 5px;
  left: 14px;
  width: 100%;
  height: 30px;
  pointer-events: none;
}
.input-textarea-box label .label-text {
  position: absolute;
  top: 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
  transform: translateY(-20px);
  left: 0px;
  background-color: #fff;
  padding: 0 5px 0;
}

.input-textarea-box.error textarea {
  border: 1px solid #f43f5e;
  color: #f43f5e;
}
.input-textarea-box.error label .label-text {
  color: #f43f5e;
}

.input-textarea-box.filled textarea {
  border: 1px solid #2563eb;
  color: #2563eb;
}
.input-textarea-box.filled label .label-text {
  color: #2563eb;
}

.input-select-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.input-select-box select {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 142.857% */
  letter-spacing: 0.1px;
  outline: none;
}
.input-select-box label {
  position: absolute;
  top: 5px;
  left: 14px;
  width: 100%;
  height: 30px;
  pointer-events: none;
}
.input-select-box label .label-text {
  position: absolute;
  top: 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
  transform: translateY(-20px);
  left: 0px;
  background-color: #fff;
  padding: 0 5px 0;
}

.input-select-box.error select {
  border: 1px solid #f43f5e;
}
.input-select-box.error label .label-text {
  color: #f43f5e;
}

.input-select-box.filled select {
  border: 1px solid #2563eb;
}
.input-select-box.filled label .label-text {
  color: #2563eb;
}

.input-box input,
.input-box textarea,
.input-box select {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.input-box input::-moz-placeholder,
.input-box textarea::-moz-placeholder,
.input-box select::-moz-placeholder {
  visibility: hidden;
}
.input-box input::placeholder,
.input-box textarea::placeholder,
.input-box select::placeholder {
  visibility: hidden;
}

.input-password-box.error input,
.input-box.error input,
.input-box.error textarea,
.input-box.error select {
  border: 1px solid #f43f5e;
}

.input-password-box.filled input,
.input-box.filled input,
.input-box.filled textarea,
.input-box.filled select {
  border: 1.5px solid #2563eb;
}

.input-box label {
  position: absolute;
  bottom: 5px;
  left: 14px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.input-box label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.label-text {
  position: absolute;
  bottom: 5px;
  left: 0px;
  padding: 0 3px 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
}

.input-box.error .label-text,
.input-password-box.error .label-text {
  color: #f43f5e;
}

.input-box.filled .label-text,
.input-password-box.filled .label-text {
  color: #2563eb;
}

.input-box input:focus {
  outline: none;
}

.input-box input:focus + .label-name .label-text,
.input-box input:valid + .label-name .label-text {
  transform: translateY(-140%);
  font-size: 14px;
  left: 0px;
  color: #64748b;
  background-color: #fff;
  padding: 0 3px 0;
}

.input-box.error input:focus + .label-name .label-text,
.input-box.error input:valid + .label-name .label-text {
  color: #f43f5e;
}

.input-box.filled input:focus + .label-name .label-text,
.input-box.filled input:valid + .label-name .label-text {
  color: #2563eb;
}

.input-box input:focus + .label-name::after,
.input-box input:valid + .label-name::after {
  transform: translateX(0%);
}

.input-box input:focus::-moz-placeholder,
.input-box input:valid::-moz-placeholder {
  visibility: visible;
}

.input-box input:focus::placeholder,
.input-box input:valid::placeholder {
  visibility: visible;
}

p.error {
  color: #f43f5e;
  font-size: 11px;
  margin-top: 4px;
  margin-left: 14px;
  letter-spacing: 0.5px;
  line-height: 16px;
}

.checkbox-input {
  position: relative;
  border-radius: 8px;
  border: 2px solid #eff6ff;
  background: #f8fafc;
  padding: 8px 16px 24px;
}
.checkbox-input-inner h5 {
  color: #475569;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
}
.checkbox-input-inner p {
  color: #64748b;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 145.455%;
  letter-spacing: 0.5px;
}
.checkbox-input input[type="checkbox"] {
  position: absolute;
  width: 100%;
  inset: 0;
  height: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}
.checkbox-input span.check {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #3b82f6;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 0 0;
}
.checkbox-input span.check::after {
  top: 5px;
  right: 10px;
  background-color: #f8fafc;
  height: 43px;
  width: 30px;
  content: "";
  position: absolute;
  rotate: 135deg;
}
.checkbox-input span.check svg {
  fill: #fff;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  right: 3px;
}

.checkbox-input:has(input[type="checkbox"]:checked) {
  border-radius: 8px;
  border: 2px solid #3b82f6;
  background: #f8fafc;
}
.checkbox-input:has(input[type="checkbox"]:checked) span.check {
  visibility: visible;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  padding: 24px;
  outline: none;
  color: blue;
  border-radius: 8px;
  border: 1px dashed #cbd5e1;
  background: #fff;
}

.custom-upload-label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
}

.upload-icon img {
  width: 24px;
  height: 24px;
}

.upload-text {
  text-align: center;
}
.upload-text span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #919094;
  line-height: 142.857%;
}
.upload-text p {
  color: green;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 145.455% */
  letter-spacing: 0.5px;
  color: #77777a;
  text-align: center;
  font-size: 11px;
}

.selected-file {
  margin-top: 20px;
  text-align: center;
}

/* Optional: Style the selected file text */
#file-name {
  font-weight: bold;
  color: #333;
}

.upload-from-csv-box-one {
  padding: 24px 0;
  border-bottom: 1px solid #f1f5f9;
  border-top: 1px solid #f1f5f9;
  margin-bottom: 24px;
}
.upload-from-csv-box-one p {
  color: #8f9099;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: 0.25px;
}
.upload-from-csv-box-one button {
  color: #2563eb;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  text-decoration: underline;
  outline: none;
  border: none;
  background-color: transparent;
  white-space: nowrap;
}
.upload-from-csv-box-two {
  margin-bottom: 48px;
}
.upload-from-csv-box-three button {
  width: 100%;
  outline: none;
  border-radius: 4px;
  background: #2563eb;
  border: 1px solid #2563eb;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding: 10px;
}

.create_role_cancel_button {
  width: 100%;
  outline: none;
  border-radius: 4px;
  background: #f1f5f9;
  border: none;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding: 10px;
  margin-right: 1rem;
}
.create_role_save_button {
  width: 100%;
  outline: none;
  border-radius: 4px;
  background: #2563eb;
  border: 1px solid #2563eb;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding: 10px 5px;
}

@media (min-width: 1560px) {
  .section-container {
    max-width: 1520px;
  }
}
.section-container {
  padding: 0 3rem;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .section-container {
    padding: 0 3rem;
  }
}
@media (max-width: 767px) {
  .section-container {
    padding: 0 2rem;
  }
}
@media (max-width: 567px) {
  .section-container {
    padding: 0 1rem;
  }
}
.auth-container {
  background: #f1f5f9;
}
.auth-container-box-one {
  height: auto;
  min-height: calc(100vh - 2.5rem);
  background: #334155;
  padding: 31px 37px;
}
.auth-container-box-one .auth-logo-box img {
  width: 120px;
}
.auth-container-box-one .auth-content-box {
  color: #f1f5f9;
}
.auth-container-box-one .auth-content-box-header {
  text-align: center;
  margin-bottom: 42px;
}
.auth-container-box-one .auth-content-box-header h3 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 127.273%;
  margin-bottom: 7px;
}
.auth-container-box-one .auth-content-box-header h6 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding-left: 22px;
  position: relative;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
}
.auth-container-box-one .auth-content-box-header h6::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 4px;
  background-color: #f1f5f9;
  top: calc(50% - 2px);
  left: 0;
  border-radius: 4px;
}
.auth-container-box-one .auth-content-box-list p {
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 145.455%;
  letter-spacing: 0.5px;
  margin-bottom: 16px !important;
}
.auth-container-box-one .auth-content-box-list-item img {
  width: 22px;
  height: 22px;
}
.auth-container-box-one .auth-content-box-list-item p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: 0.25px;
  margin-bottom: 0 !important;
}
.auth-container-box-two {
  background: #fff;
  padding: 20px;
  min-height: calc(100vh - 2.5rem);
  height: auto;
  overflow-y: scroll;
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #334155 #f1f1f1;
}
.auth-container-box-two::-webkit-scrollbar {
  width: 6px;
}
.auth-container-box-two::-webkit-scrollbar-thumb {
  background: #5b6779;
  border-radius: 2px;
}
.auth-container-box-two::-webkit-scrollbar-track {
  background: #fff;
}
.auth-container-box-two-step-box h6 {
  color: #334155;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
}
.auth-container-box-two-step-box div span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #dbeafe;
}
.auth-container-box-two-step-box div span.active {
  background-color: #3b82f6;
}
.auth-container-box-two-content-header {
  margin-bottom: 56px;
}
.auth-container-box-two-content-header h4 {
  color: #334155;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 127.273%;
}
.auth-container-box-two-content-header p {
  color: #64748b;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.2px;
}
.auth-container-box-two-content .add-user-btn {
  background-color: transparent;
  border: none;
  outline: none;
}
.auth-container-box-two-content .add-user-btn .icon svg {
  width: 20px;
  height: 20px;
}
.auth-container-box-two-content .add-user-btn .text {
  color: #2563eb;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 166.667%;
  letter-spacing: 0.1px;
}
.auth-container-box-two-content-body form .terms-and-condition p {
  color: #334155;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.5px;
}
.auth-container-box-two-content-body form .terms-and-condition p a {
  color: #3b82f6;
}
.auth-container-box-two-content-body form .more-info p {
  color: #3b82f6;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.5px;
  margin-top: 4px;
}
.auth-container-box-two-content-body form .submit-btn button {
  width: 100%;
  outline: none;
  border-radius: 4px;
  background: #2563eb;
  border: 1px solid #2563eb;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding: 10px;
}
.auth-container-box-two-content-body form .submit-btn p span.icon {
  width: 12px;
  height: 12px;
}
.auth-container-box-two-content-body form .submit-btn p span.text {
  color: #2563eb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: 0.1px;
}
.auth-container-box-two-content-body .divider {
  position: relative;
}
.auth-container-box-two-content-body .divider p {
  color: #334155;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: 0.1px;
}
.auth-container-box-two-content-body .external-login h6 {
  color: #334155;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
}
.auth-container-box-two-content-body .external-login div {
  margin-bottom: 32px;
}
.auth-container-box-two-content-body .external-login div span {
  padding: 5px 6px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-container-box-two-content-body .external-login div span svg {
  width: 24px;
  height: 24px;
}
.auth-container-box-two-content-body .external-login p {
  color: #334155;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.5px;
}

@media (max-width: 1023px) {
  .auth-container {
    background: #f1f5f9;
  }
  .auth-container-box-one {
    height: calc(100vh - 5rem);
    background: #334155;
    padding: 31px 37px;
  }
  .auth-container-box-one .auth-logo-box img {
    width: 120px;
  }
  .auth-container-box-one .auth-content-box {
    color: #f1f5f9;
  }
  .auth-container-box-one .auth-content-box-header {
    text-align: center;
    margin-bottom: 42px;
  }
  .auth-container-box-one .auth-content-box-header h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 127.273%;
    margin-bottom: 7px;
  }
  .auth-container-box-one .auth-content-box-header h6 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 142.857%;
    letter-spacing: 0.1px;
    padding-left: 22px;
    position: relative;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto;
  }
  .auth-container-box-one .auth-content-box-header h6::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 4px;
    background-color: #f1f5f9;
    top: calc(50% - 2px);
    left: 0;
    border-radius: 4px;
  }
  .auth-container-box-one .auth-content-box-list p {
    color: #fff;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 145.455%;
    letter-spacing: 0.5px;
    margin-bottom: 16px !important;
  }
  .auth-container-box-one .auth-content-box-list-item img {
    width: 22px;
    height: 22px;
  }
  .auth-container-box-one .auth-content-box-list-item p {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    letter-spacing: 0.25px;
    margin-bottom: 0 !important;
  }
  .auth-container-box-two {
    background: #fff;
    padding: 20px;
    height: auto;
    min-height: 100vh;
    overflow-y: scroll;
    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #334155 #f1f1f1;
  }
  .auth-container-box-two::-webkit-scrollbar {
    width: 6px;
  }
  .auth-container-box-two::-webkit-scrollbar-thumb {
    background: #5b6779;
    border-radius: 2px;
  }
  .auth-container-box-two::-webkit-scrollbar-track {
    background: #fff;
  }
  .auth-container-box-two-step-box h6 {
    color: #334155;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 142.857%;
    letter-spacing: 0.1px;
  }
  .auth-container-box-two-step-box div span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #dbeafe;
  }
  .auth-container-box-two-step-box div span.active {
    background-color: #3b82f6;
  }
  .auth-container-box-two-content-header {
    margin-bottom: 36px;
  }
  .auth-container-box-two-content-header h4 {
    color: #334155;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 127.273%;
  }
  .auth-container-box-two-content-header p {
    color: #64748b;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.2px;
  }
  .auth-container-box-two-content .add-user-btn {
    background-color: transparent;
    border: none;
    outline: none;
  }
  .auth-container-box-two-content .add-user-btn .icon svg {
    width: 20px;
    height: 20px;
  }
  .auth-container-box-two-content .add-user-btn .text {
    color: #2563eb;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 166.667%;
    letter-spacing: 0.1px;
  }
  .auth-container-box-two-content-body form .terms-and-condition p {
    color: #334155;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.5px;
  }
  .auth-container-box-two-content-body form .terms-and-condition p a {
    color: #3b82f6;
  }
  .auth-container-box-two-content-body form .more-info p {
    color: #3b82f6;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.5px;
    margin-top: 4px;
  }
  .auth-container-box-two-content-body form .submit-btn button {
    width: 100%;
    outline: none;
    border-radius: 4px;
    background: #2563eb;
    border: 1px solid #2563eb;
    box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 142.857%;
    letter-spacing: 0.1px;
    padding: 10px;
  }
  .auth-container-box-two-content-body form .submit-btn p span.icon {
    width: 12px;
    height: 12px;
  }
  .auth-container-box-two-content-body form .submit-btn p span.text {
    color: #2563eb;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.857%;
    letter-spacing: 0.1px;
  }
  .auth-container-box-two-content-body .divider {
    position: relative;
  }
  .auth-container-box-two-content-body .divider p {
    color: #334155;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.857%;
    letter-spacing: 0.1px;
  }
  .auth-container-box-two-content-body .external-login h6 {
    color: #334155;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.15px;
    margin-bottom: 24px;
  }
  .auth-container-box-two-content-body .external-login div {
    margin-bottom: 32px;
  }
  .auth-container-box-two-content-body .external-login div span {
    padding: 5px 6px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .auth-container-box-two-content-body .external-login div span svg {
    width: 24px;
    height: 24px;
  }
  .auth-container-box-two-content-body .external-login p {
    color: #334155;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.5px;
  }
}
.second-auth-container {
  background-color: #f1f5f9;
  min-height: 100vh;
  width: 100vw;
}
.second-auth-container-box {
  min-height: 450px;
  border-radius: 4px;
}
.second-auth-container-box-header .logo-box {
  margin-bottom: 8px;
}
.second-auth-container-box-header h5 {
  color: #334155;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 127.273%;
}
.second-auth-container-box-header p {
  color: #64748b;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.5px;
}
.second-auth-container-box-body form .forgot-password a {
  text-decoration: none;
}
.second-auth-container-box-body form .forgot-password a p {
  color: #3b82f6;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}
.second-auth-container-box-body form .submit-btn button {
  width: 100%;
  outline: none;
  border-radius: 4px;
  background: #2563eb;
  border: 1px solid #2563eb;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding: 10px;
}
.second-auth-container-box-body form .submit-btn p span.icon {
  width: 12px;
  height: 12px;
}
.second-auth-container-box-body form .submit-btn p span.text {
  color: #2563eb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: 0.1px;
}
.second-auth-container-box-body-footer a {
  text-decoration: none;
}
.second-auth-container-box-body-footer a p {
  color: #3b82f6;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.5px;
}
.second-auth-container-box-body .external-login {
  margin-top: 28px;
}
.second-auth-container-box-body .external-login span.line {
  height: 0.2px;
  background-color: #e2e8f0;
  padding: 0 !important;
}
.second-auth-container-box-body .external-login h6 {
  color: #334155;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: 0.1px;
}
.second-auth-container-box-body .external-login div {
  margin-bottom: 28px;
}
.second-auth-container-box-body .external-login div span {
  padding: 5px 6px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second-auth-container-box-body .external-login div span svg {
  width: 24px;
  height: 24px;
}
.second-auth-container-box-body .external-login p {
  color: #334155;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: 0.5px;
}

.add-user-btn {
  background-color: transparent;
  border: none;
  outline: none;
}
.add-user-btn .icon svg {
  width: 20px;
  height: 20px;
}
.add-user-btn .text {
  color: #2563eb;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 166.667%;
  letter-spacing: 0.1px;
}

button.small-add-csv {
  padding: 4px 10px;
  border-radius: 4px;
  background: #f8fafc;
  outline: none;
  border: none;
  gap: 4px;
}
button.small-add-csv .text {
  color: #334155;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.846%;
  letter-spacing: 0.1px;
}
button.small-add-csv .icon img {
  width: 20px;
  height: 20px;
} /*# sourceMappingURL=main.css.map */
