button.main-btn {
  width: 100%;
  outline: none;
  border-radius: 4px;
  background: #2563eb;
  border: 1px solid #2563eb;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding: 10px;
}
.input-box ~ p {
  color: #23222d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 10px;
}

.input-box.error ~ p {
  color: #f43f5e;
}

.input-box.filled ~ p {
  color: #2563eb;
}

.input-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}

.input-date-box {
  width: 100%;
  position: relative;
  background-color: transparent;
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #64748b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 142.857% */
    letter-spacing: 0.1px;
    outline: none;
  }
  label {
    position: absolute;
    bottom: 5px;
    left: 14px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    .label-text {
      position: absolute;
      bottom: 5px;
      transition: all 0.3s ease;
      color: #64748b;
      font-size: 13px;
      transform: translateY(-140%);
      left: 0px;
      background-color: #fff;
      padding: 0 3px 0;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
    }
  }
}

.input-date-box.error {
  input {
    border: 1px solid #f43f5e;
    color: #f43f5e;
  }
  label {
    .label-text {
      color: #f43f5e;
    }
  }
}

.input-date-box.filled {
  input {
    border: 1px solid #2563eb;
  }
  label {
    .label-text {
      color: #2563eb;
    }
  }
}

.input-password-box {
  width: 100%;
  position: relative;
  background-color: transparent;
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 30px 12px 16px;
    outline: none;
    color: #64748b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 142.857% */
    letter-spacing: 0.1px;
    outline: none;
  }
  .view {
    position: absolute;
    padding: 17px;
    right: 1px;
    svg {
      fill: #64748b;
    }
  }
  label {
    position: absolute;
    top: 5px;
    left: 14px;
    width: 100%;
    height: 30px;
    pointer-events: none;
    .label-text {
      position: absolute;
      top: 5px;
      transition: all 0.3s ease;
      color: #64748b;
      font-size: 13px;
      transform: translateY(-20px);
      left: 0px;
      background-color: #fff;
      padding: 0 5px 0;
    }
  }
}

.input-textarea-box {
  width: 100%;
  position: relative;
  background-color: transparent;
  textarea {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #64748b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 142.857% */
    letter-spacing: 0.1px;
    outline: none;
  }
  label {
    position: absolute;
    top: 5px;
    left: 14px;
    width: 100%;
    height: 30px;
    pointer-events: none;
    .label-text {
      position: absolute;
      top: 5px;
      transition: all 0.3s ease;
      color: #64748b;
      font-size: 13px;
      transform: translateY(-20px);
      left: 0px;
      background-color: #fff;
      padding: 0 5px 0;
    }
  }
}

.input-textarea-box.error {
  textarea {
    border: 1px solid #f43f5e;
    color: #f43f5e;
  }
  label {
    .label-text {
      color: #f43f5e;
    }
  }
}

.input-textarea-box.filled {
  textarea {
    border: 1px solid #2563eb;
    color: #2563eb;
  }
  label {
    .label-text {
      color: #2563eb;
    }
  }
}

.input-select-box {
  width: 100%;
  position: relative;
  background-color: transparent;
  select {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #64748b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 142.857% */
    letter-spacing: 0.1px;
    outline: none;
  }
  label {
    position: absolute;
    top: 5px;
    left: 14px;
    width: 100%;
    height: 30px;
    pointer-events: none;
    .label-text {
      position: absolute;
      top: 5px;
      transition: all 0.3s ease;
      color: #64748b;
      font-size: 13px;
      transform: translateY(-20px);
      left: 0px;
      background-color: #fff;
      padding: 0 5px 0;
    }
  }
}

.input-select-box.error {
  select {
    border: 1px solid #f43f5e;
  }
  label {
    .label-text {
      color: #f43f5e;
    }
  }
}

.input-select-box.filled {
  select {
    border: 1px solid #2563eb;
  }
  label {
    .label-text {
      color: #2563eb;
    }
  }
}

.input-box input,
.input-box textarea,
.input-box select {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  &::placeholder {
    visibility: hidden;
  }
}

.input-password-box.error input,
.input-box.error input,
.input-box.error textarea,
.input-box.error select {
  border: 1px solid #f43f5e;
}

.input-password-box.filled input,
.input-box.filled input,
.input-box.filled textarea,
.input-box.filled select {
  border: 1.5px solid #2563eb;
}

.input-box label {
  position: absolute;
  bottom: 5px;
  left: 14px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.input-box label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.label-text {
  position: absolute;
  bottom: 5px;
  left: 0px;
  padding: 0 3px 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
}
.input-box.error .label-text,
.input-password-box.error .label-text {
  color: #f43f5e;
}

.input-box.filled .label-text,
.input-password-box.filled .label-text {
  color: #2563eb;
}

.input-box input:focus {
  outline: none;
}

.input-box input:focus + .label-name .label-text,
.input-box input:valid + .label-name .label-text {
  transform: translateY(-140%);
  font-size: 14px;
  left: 0px;
  color: #64748b;
  background-color: #fff;

  padding: 0 3px 0;
}

.input-box.error input:focus + .label-name .label-text,
.input-box.error input:valid + .label-name .label-text {
  color: #f43f5e;
}

.input-box.filled input:focus + .label-name .label-text,
.input-box.filled input:valid + .label-name .label-text {
  color: #2563eb;
}

.input-box input:focus + .label-name::after,
.input-box input:valid + .label-name::after {
  transform: translateX(0%);
}

.input-box input:focus,
.input-box input:valid {
  &::placeholder {
    visibility: visible;
  }
}

p.error {
  color: #f43f5e;
  font-size: 11px;
  margin-top: 4px;
  margin-left: 14px;
  letter-spacing: 0.5px;
  line-height: 16px;
}

.checkbox-input {
  position: relative;
  border-radius: 8px;
  border: 2px solid #eff6ff;
  background: #f8fafc;
  padding: 8px 16px 24px;
  &-inner {
    h5 {
      color: #475569;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.15px;
    }
    p {
      color: #64748b;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 145.455%;
      letter-spacing: 0.5px;
    }
  }
  input[type="checkbox"] {
    position: absolute;
    width: 100%;
    inset: 0;
    height: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
  }
  span.check {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #3b82f6;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 0 0;
    &::after {
      top: 5px;
      right: 10px;
      background-color: #f8fafc;
      height: 43px;
      width: 30px;
      content: "";
      position: absolute;
      rotate: 135deg;
    }
    svg {
      fill: #fff;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 5px;
      right: 3px;
    }
  }
}

.checkbox-input:has(input[type="checkbox"]:checked) {
  border-radius: 8px;
  border: 2px solid #3b82f6;
  background: #f8fafc;
  span.check {
    visibility: visible;
  }
}
