@import "./form";

@media (min-width: 1560px) {
  .section-container {
    max-width: 1520px;
  }
}

.section-container {
  padding: 0 3rem;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .section-container {
    padding: 0 3rem;
  }
}

@media (max-width: 767px) {
  .section-container {
    padding: 0 2rem;
  }
}

@media (max-width: 567px) {
  .section-container {
    padding: 0 1rem;
  }
}

.onboarding-container {
  background: #f1f5f9;
  &-main-box {
    height: auto;
    min-height: calc(100vh - 2rem);
    &-body {
      position: relative;
      border-radius: 8px;
      // background: #fff;
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
      z-index: 1;
      position: relative;
      &-footer {
        background: #fff;
        box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        button {
          border-radius: 4px;
          box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
      &::after {
        content: "";
        background-color: #e2e8f0;
        height: 80%;
        top: 10%;
        width: 106%;
        left: -3%;
        position: absolute;
        z-index: -1;
      }
    }
  }

  &-box-one {
    height: auto;
    min-height: calc(100vh - 4rem - 73px);
    // background: #fff;
    &-body {
      .onboarding-step {
        &-box {
          &-number {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 2px solid #cbd5e1;
            span.icon {
              display: none;
            }
            span.number {
              color: #cbd5e1;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              letter-spacing: 0.15px;
            }
          }

          &-title {
            h6 {
              color: #cbd5e1;
              font-style: normal;
              letter-spacing: 0.15px;
            }
          }
        }

        &-box:last-of-type {
          .step-line {
            display: none;
          }
        }

        .onboarding-step-box.active .onboarding-step-box-number {
          border: 2px solid #2563eb;
          span.icon {
            display: none;
          }
          span.number {
            color: #2563eb;
          }
        }

        .onboarding-step-box.active .onboarding-step-box-title h6 {
          color: #2563eb;
        }

        .onboarding-step-box.completed .onboarding-step-box-number {
          border: 2px solid #2563eb;
          span.icon {
            display: block;
          }
          span.number {
            display: none;
          }
        }

        .onboarding-step-box.completed .onboarding-step-box-title h6 {
          color: #cbd5e1;
        }
      }
    }
  }

  &-box-two {
    // background: #fff;
    padding: 20px;
    max-height: 100%;
    overflow-y: scroll;
    // background-color: #fff;
    height: auto;
    min-height: calc(100vh - 4rem - 73px);
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #5b6779;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #334155 #f1f1f1;
  }
}

.onboarding-file-upload {
  .file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    padding: 10px !important;
    outline: none;
    color: blue;
    border-radius: 8px;
    border: 1px dashed #cbd5e1;
    background: #fff;
  }

  .custom-upload-label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: 2px dashed #ccc;
    border-radius: 8px;
  }

  .upload-icon img {
    width: 24px;
    height: 24px;
  }

  .upload-text {
    text-align: center;
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #919094;
      line-height: 142.857%;
    }

    p {
      color: green;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 145.455% */
      letter-spacing: 0.5px;
      color: #77777a;
      text-align: center;
      font-size: 11px;
    }
  }

  .selected-file {
    margin-top: 20px;
    text-align: center;
  }

  /* Optional: Style the selected file text */
  #file-name {
    font-weight: bold;
    color: #333;
  }
}

@media (max-width: 1023px) {
  .onboarding-container {
    padding: 0rem 0;
    background: #f1f5f9;
    &-main-box {
      height: auto;
      min-height: 100vh;
      &-body {
        position: relative;
        border-radius: 8px;
        // background: #fff;
        box-shadow: none;
        z-index: 1;
        position: relative;
        &-footer {
          background: #fff;
          box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;

          button {
            border-radius: 4px;
            box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.1px;
          }
        }
        &::after {
          display: none;
        }
      }
    }

    &-box-one {
      background: #fff;
      min-height: auto;
      &-body {
        .onboarding-step {
          &-box {
            &-number {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 2px solid #cbd5e1;
              span.icon {
                display: none;
              }
              span.number {
                color: #cbd5e1;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.15px;
              }
            }

            &-title {
              h6 {
                color: #cbd5e1;
                font-style: normal;
                letter-spacing: 0.15px;
              }
            }
          }

          &-box:last-of-type {
            .step-line {
              display: none;
            }
          }

          .onboarding-step-box.active .onboarding-step-box-number {
            border: 2px solid #2563eb;
            span.icon {
              display: none;
            }
            span.number {
              color: #2563eb;
            }
          }

          .onboarding-step-box.active .onboarding-step-box-title h6 {
            color: #2563eb;
          }

          .onboarding-step-box.completed .onboarding-step-box-number {
            border: 2px solid #2563eb;
            span.icon {
              display: block;
            }
            span.number {
              display: none;
            }
          }

          .onboarding-step-box.completed .onboarding-step-box-title h6 {
            color: #cbd5e1;
          }
        }
      }
    }

    &-box-two {
      // background: #fff;
      padding: 20px;
      max-height: auto;
      overflow-y: scroll;
      // background-color: #fff;
      height: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #5b6779;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: #334155 #f1f1f1;
    }
  }
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  // background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
  //   conic-gradient(#2563eb 50%, #ccc 0);
}
