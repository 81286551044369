/** @format */
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --bodyColor: "#E2E8F0";
}
body {
  background-color: var(--bodyColor);
}
.input-group {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.modalShadow {
  box-shadow: 0px 8px 20px 0px rgba(37, 99, 235, 0.1);
}

.emailChangeModal::placeholder {
  color: #64748b;
}

.btn-disabled:disabled {
  border-color: #cbd5e1;
  color: #cbd5e1;
}

.del-shadow {
  box-shadow: 0px 8px 20px 0px rgba(37, 99, 235, 0.1);
}

.loader {
  width: 16px;
  height: 16px;
  position: relative;
  left: -32px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  box-shadow: 32px 0, -32px 0, 64px 0;
}

.loader::after {
  content: "";
  position: absolute;
  left: -32px;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background: blue;
  animation: move 3s linear infinite alternate;
}

@keyframes move {
  0%,
  5% {
    left: -32px;
    width: 16px;
  }
  15%,
  20% {
    left: -32px;
    width: 48px;
  }
  30%,
  35% {
    left: 0px;
    width: 16px;
  }
  45%,
  50% {
    left: 0px;
    width: 48px;
  }
  60%,
  65% {
    left: 32px;
    width: 16px;
  }

  75%,
  80% {
    left: 32px;
    width: 48px;
  }
  95%,
  100% {
    left: 64px;
    width: 16px;
  }
}

.main-loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #00000090;
  top: 0;
  left: 0;
  z-index: 100000;
}
