button.main-btn {
  width: 100%;
  outline: none;
  border-radius: 4px;
  background: #2563eb;
  border: 1px solid #2563eb;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  padding: 10px;
}

.input-box ~ p {
  color: #23222d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 10px;
}

.input-box.error ~ p {
  color: #f43f5e;
}

.input-box.filled ~ p {
  color: #2563eb;
}

.input-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}

.input-date-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.input-date-box input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 142.857% */
  letter-spacing: 0.1px;
  outline: none;
}
.input-date-box label {
  position: absolute;
  bottom: 5px;
  left: 14px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.input-date-box label .label-text {
  position: absolute;
  bottom: 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
  transform: translateY(-140%);
  left: 0px;
  background-color: #fff;
  padding: 0 3px 0;
}
.input-date-box label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.input-date-box.error input {
  border: 1px solid #f43f5e;
  color: #f43f5e;
}
.input-date-box.error label .label-text {
  color: #f43f5e;
}

.input-date-box.filled input {
  border: 1px solid #2563eb;
}
.input-date-box.filled label .label-text {
  color: #2563eb;
}

.input-password-box .input-plain-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.input-plain-box input {
}
.input-password-box input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 30px 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 142.857% */
  letter-spacing: 0.1px;
  outline: none;
}
.input-password-box .view {
  position: absolute;
  padding: 17px;
  right: 1px;
}
.input-password-box .view svg {
  fill: #64748b;
}
.input-password-box label {
  position: absolute;
  top: 5px;
  left: 14px;
  width: 100%;
  height: 30px;
  pointer-events: none;
}
.input-password-box label .label-text {
  position: absolute;
  top: 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
  transform: translateY(-20px);
  left: 0px;
  background-color: #fff;
  padding: 0 5px 0;
}

.input-textarea-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.input-textarea-box textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 142.857% */
  letter-spacing: 0.1px;
  outline: none;
}
.input-textarea-box label {
  position: absolute;
  top: 5px;
  left: 14px;
  width: 100%;
  height: 30px;
  pointer-events: none;
}
.input-textarea-box label .label-text {
  position: absolute;
  top: 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
  transform: translateY(-20px);
  left: 0px;
  background-color: #fff;
  padding: 0 5px 0;
}

.input-textarea-box.error textarea {
  border: 1px solid #f43f5e;
  color: #f43f5e;
}
.input-textarea-box.error label .label-text {
  color: #f43f5e;
}

.input-textarea-box.filled textarea {
  border: 1px solid #2563eb;
  color: #2563eb;
}
.input-textarea-box.filled label .label-text {
  color: #2563eb;
}

.input-select-box {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.input-select-box select {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 142.857% */
  letter-spacing: 0.1px;
  outline: none;
}
.input-select-box label {
  position: absolute;
  top: 5px;
  left: 14px;
  width: 100%;
  height: 30px;
  pointer-events: none;
}
.input-select-box label .label-text {
  position: absolute;
  top: 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
  transform: translateY(-20px);
  left: 0px;
  background-color: #fff;
  padding: 0 5px 0;
}

.input-select-box.error select {
  border: 1px solid #f43f5e;
}
.input-select-box.error label .label-text {
  color: #f43f5e;
}

.input-select-box.filled select {
  border: 1px solid #2563eb;
}
.input-select-box.filled label .label-text {
  color: #2563eb;
}

.input-box input,
.input-box textarea,
.input-box select {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #64748b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.input-box input::-moz-placeholder,
.input-box textarea::-moz-placeholder,
.input-box select::-moz-placeholder {
  visibility: hidden;
}
.input-box input::placeholder,
.input-box textarea::placeholder,
.input-box select::placeholder {
  visibility: hidden;
}

.input-password-box.error input,
.input-box.error input,
.input-box.error textarea,
.input-box.error select {
  border: 1px solid #f43f5e;
}

.input-password-box.filled input,
.input-box.filled input,
.input-box.filled textarea,
.input-box.filled select {
  border: 1.5px solid #2563eb;
}

.input-box label {
  position: absolute;
  bottom: 5px;
  left: 14px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.input-box label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.label-text {
  position: absolute;
  bottom: 5px;
  left: 0px;
  padding: 0 3px 5px;
  transition: all 0.3s ease;
  color: #64748b;
  font-size: 13px;
}

.input-box.error .label-text,
.input-password-box.error .label-text {
  color: #f43f5e;
}

.input-box.filled .label-text,
.input-password-box.filled .label-text {
  color: #2563eb;
}

.input-box input:focus {
  outline: none;
}

.input-box input:focus + .label-name .label-text,
.input-box input:valid + .label-name .label-text {
  transform: translateY(-140%);
  font-size: 14px;
  left: 0px;
  color: #64748b;
  background-color: #fff;
  padding: 0 3px 0;
}

.input-box.error input:focus + .label-name .label-text,
.input-box.error input:valid + .label-name .label-text {
  color: #f43f5e;
}

.input-box.filled input:focus + .label-name .label-text,
.input-box.filled input:valid + .label-name .label-text {
  color: #2563eb;
}

.input-box input:focus + .label-name::after,
.input-box input:valid + .label-name::after {
  transform: translateX(0%);
}

.input-box input:focus::-moz-placeholder,
.input-box input:valid::-moz-placeholder {
  visibility: visible;
}

.input-box input:focus::placeholder,
.input-box input:valid::placeholder {
  visibility: visible;
}

p.error {
  color: #f43f5e;
  font-size: 11px;
  margin-top: 4px;
  margin-left: 14px;
  letter-spacing: 0.5px;
  line-height: 16px;
}

.checkbox-input {
  position: relative;
  border-radius: 8px;
  border: 2px solid #eff6ff;
  background: #f8fafc;
  padding: 8px 16px 24px;
}
.checkbox-input-inner h5 {
  color: #475569;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
}
.checkbox-input-inner p {
  color: #64748b;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 145.455%;
  letter-spacing: 0.5px;
}
.checkbox-input input[type="checkbox"] {
  position: absolute;
  width: 100%;
  inset: 0;
  height: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}
.checkbox-input span.check {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #3b82f6;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 0 0;
}
.checkbox-input span.check::after {
  top: 5px;
  right: 10px;
  background-color: #f8fafc;
  height: 43px;
  width: 30px;
  content: "";
  position: absolute;
  rotate: 135deg;
}
.checkbox-input span.check svg {
  fill: #fff;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  right: 3px;
}

.checkbox-input:has(input[type="checkbox"]:checked) {
  border-radius: 8px;
  border: 2px solid #3b82f6;
  background: #f8fafc;
}
.checkbox-input:has(input[type="checkbox"]:checked) span.check {
  visibility: visible;
}

@media (min-width: 1560px) {
  .section-container {
    max-width: 1520px;
  }
}
.section-container {
  padding: 0 3rem;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .section-container {
    padding: 0 3rem;
  }
}
@media (max-width: 767px) {
  .section-container {
    padding: 0 2rem;
  }
}
@media (max-width: 567px) {
  .section-container {
    padding: 0 1rem;
  }
}
.onboarding-container {
  background: #f1f5f9;
}
.onboarding-container-main-box {
  height: auto;
  min-height: calc(100vh - 2rem);
}
.onboarding-container-main-box-body {
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
}
.onboarding-container-main-box-body-footer {
  background: #fff;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.onboarding-container-main-box-body-footer button {
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.onboarding-container-main-box-body::after {
  content: "";
  background-color: #e2e8f0;
  height: 80%;
  top: 10%;
  width: 106%;
  left: -3%;
  position: absolute;
  z-index: -1;
}
.onboarding-container-box-one {
  height: auto;
  min-height: calc(100vh - 4rem - 73px);
}
.onboarding-container-box-one-body .onboarding-step-box-number {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #cbd5e1;
}
.onboarding-container-box-one-body .onboarding-step-box-number span.icon {
  display: none;
}
.onboarding-container-box-one-body .onboarding-step-box-number span.number {
  color: #cbd5e1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
}
.onboarding-container-box-one-body .onboarding-step-box-title h6 {
  color: #cbd5e1;
  font-style: normal;
  letter-spacing: 0.15px;
}
.onboarding-container-box-one-body
  .onboarding-step-box:last-of-type
  .step-line {
  display: none;
}
.onboarding-container-box-one-body
  .onboarding-step
  .onboarding-step-box.active
  .onboarding-step-box-number {
  border: 2px solid #2563eb;
}
.onboarding-container-box-one-body
  .onboarding-step
  .onboarding-step-box.active
  .onboarding-step-box-number
  span.icon {
  display: none;
}
.onboarding-container-box-one-body
  .onboarding-step
  .onboarding-step-box.active
  .onboarding-step-box-number
  span.number {
  color: #2563eb;
}
.onboarding-container-box-one-body
  .onboarding-step
  .onboarding-step-box.active
  .onboarding-step-box-title
  h6 {
  color: #2563eb;
}
.onboarding-container-box-one-body
  .onboarding-step
  .onboarding-step-box.completed
  .onboarding-step-box-number {
  border: 2px solid #2563eb;
}
.onboarding-container-box-one-body
  .onboarding-step
  .onboarding-step-box.completed
  .onboarding-step-box-number
  span.icon {
  display: block;
}
.onboarding-container-box-one-body
  .onboarding-step
  .onboarding-step-box.completed
  .onboarding-step-box-number
  span.number {
  display: none;
}
.onboarding-container-box-one-body
  .onboarding-step
  .onboarding-step-box.completed
  .onboarding-step-box-title
  h6 {
  color: #cbd5e1;
}
.onboarding-container-box-two {
  padding: 20px;
  max-height: 100%;
  overflow-y: scroll;
  height: auto;
  min-height: calc(100vh - 4rem - 73px);
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #334155 #f1f1f1;
}
.onboarding-container-box-two::-webkit-scrollbar {
  width: 6px;
}
.onboarding-container-box-two::-webkit-scrollbar-thumb {
  background: #5b6779;
  border-radius: 2px;
}
.onboarding-container-box-two::-webkit-scrollbar-track {
  background: #fff;
}

.onboarding-file-upload {
  /* Optional: Style the selected file text */
}
.onboarding-file-upload .file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  padding: 10px !important;
  outline: none;
  color: blue;
  border-radius: 8px;
  border: 1px dashed #cbd5e1;
  background: #fff;
}
.onboarding-file-upload .custom-upload-label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.onboarding-file-upload .upload-icon img {
  width: 24px;
  height: 24px;
}
.onboarding-file-upload .upload-text {
  text-align: center;
}
.onboarding-file-upload .upload-text span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #919094;
  line-height: 142.857%;
}
.onboarding-file-upload .upload-text p {
  color: green;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 145.455% */
  letter-spacing: 0.5px;
  color: #77777a;
  text-align: center;
  font-size: 11px;
}
.onboarding-file-upload .selected-file {
  margin-top: 20px;
  text-align: center;
}
.onboarding-file-upload #file-name {
  font-weight: bold;
  color: #333;
}

@media (max-width: 1023px) {
  .onboarding-container {
    padding: 0rem 0;
    background: #f1f5f9;
  }
  .onboarding-container-main-box {
    height: auto;
    min-height: 100vh;
  }
  .onboarding-container-main-box-body {
    position: relative;
    border-radius: 8px;
    box-shadow: none;
    z-index: 1;
    position: relative;
  }
  .onboarding-container-main-box-body-footer {
    background: #fff;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .onboarding-container-main-box-body-footer button {
    border-radius: 4px;
    box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
  .onboarding-container-main-box-body::after {
    display: none;
  }
  .onboarding-container-box-one {
    background: #fff;
    min-height: auto;
  }
  .onboarding-container-box-one-body .onboarding-step-box-number {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #cbd5e1;
  }
  .onboarding-container-box-one-body .onboarding-step-box-number span.icon {
    display: none;
  }
  .onboarding-container-box-one-body .onboarding-step-box-number span.number {
    color: #cbd5e1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .onboarding-container-box-one-body .onboarding-step-box-title h6 {
    color: #cbd5e1;
    font-style: normal;
    letter-spacing: 0.15px;
  }
  .onboarding-container-box-one-body
    .onboarding-step-box:last-of-type
    .step-line {
    display: none;
  }
  .onboarding-container-box-one-body
    .onboarding-step
    .onboarding-step-box.active
    .onboarding-step-box-number {
    border: 2px solid #2563eb;
  }
  .onboarding-container-box-one-body
    .onboarding-step
    .onboarding-step-box.active
    .onboarding-step-box-number
    span.icon {
    display: none;
  }
  .onboarding-container-box-one-body
    .onboarding-step
    .onboarding-step-box.active
    .onboarding-step-box-number
    span.number {
    color: #2563eb;
  }
  .onboarding-container-box-one-body
    .onboarding-step
    .onboarding-step-box.active
    .onboarding-step-box-title
    h6 {
    color: #2563eb;
  }
  .onboarding-container-box-one-body
    .onboarding-step
    .onboarding-step-box.completed
    .onboarding-step-box-number {
    border: 2px solid #2563eb;
  }
  .onboarding-container-box-one-body
    .onboarding-step
    .onboarding-step-box.completed
    .onboarding-step-box-number
    span.icon {
    display: block;
  }
  .onboarding-container-box-one-body
    .onboarding-step
    .onboarding-step-box.completed
    .onboarding-step-box-number
    span.number {
    display: none;
  }
  .onboarding-container-box-one-body
    .onboarding-step
    .onboarding-step-box.completed
    .onboarding-step-box-title
    h6 {
    color: #cbd5e1;
  }
  .onboarding-container-box-two {
    padding: 20px;
    max-height: auto;
    overflow-y: scroll;
    height: auto;
    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #334155 #f1f1f1;
  }
  .onboarding-container-box-two::-webkit-scrollbar {
    width: 6px;
  }
  .onboarding-container-box-two::-webkit-scrollbar-thumb {
    background: #5b6779;
    border-radius: 2px;
  }
  .onboarding-container-box-two::-webkit-scrollbar-track {
    background: #fff;
  }
}
.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
} /*# sourceMappingURL=onboarding-main.css.map */
