.upload-from-csv {
    &-box-one {
      padding: 24px 0;
      border-bottom: 1px solid #f1f5f9;
  
      border-top: 1px solid #f1f5f9;
      margin-bottom: 24px;
      p {
        color: #8f9099;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
        letter-spacing: 0.25px;
      }
      button {
        color: #2563eb;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 142.857%;
        letter-spacing: 0.1px;
        text-decoration: underline;
        outline: none;
        border: none;
        background-color: transparent;
        white-space: nowrap;
      }
    }
  
    &-box-two {
      margin-bottom: 48px;
    }
  
    &-box-three {
      button {
        width: 100%;
        outline: none;
        border-radius: 4px;
        background: #2563eb;
        border: 1px solid #2563eb;
        box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 142.857%;
        letter-spacing: 0.1px;
        padding: 10px;
      }
    }
  }
  