@import "./config";
@import "./dashboard-pages";

.dashboard {
  padding: 0;
  margin: 0;
  &-side-nav {
    min-width: $sidenav-width;
    background-color: $white;
    border-right: 1px solid $grey-4;
    height: 100vh;
    overflow-y: auto;
    position: sticky;
    top: 0;
    left: 0;
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: $light-blue-3;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: $light-blue-3 #f1f1f1;
    &-logo-box {
      padding: 20px 36px;
      margin-bottom: 20px;
    }
    &-list {
      padding-bottom: 20px;
      ul {
        list-style-type: none;
        li {
          margin-bottom: 10px;
          a,
          .title-box {
            padding: 10px 25px;
            display: flex;
            align-items: center;
            gap: 18px;
            text-decoration: none;
            color: $grey-1;
            cursor: pointer;
            .icon {
              width: 18px;
            }
            .text {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.2px;
            }
          }

          a.active {
            background-color: #2563eb;
            color: #fff;
            border-radius: 56px 0 0 56px;
            .icon {
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }

      &-item {
        .title-box {
          .arrow {
            rotate: 180deg;
            transition: all 0.2s ease-in-out;
            svg {
              width: 10px;
            }
          }
        }
        .content {
          padding-top: 0.25em;
          height: 0;
          overflow: hidden;
        }
      }

      &-item.open {
        .title-box {
          background-color: transparent;
          .arrow {
            rotate: 0deg;
          }
        }
        .content {
          height: auto;
        }
      }

      &-item:has(.submenu-link.active) {
        .title-box {
          background-color: #2563eb;
          color: #fff;
          border-radius: 56px 0 0 56px;
          .icon {
            svg {
              path {
                fill: #fff;
              }
            }
          }
          .arrow {
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      &-item.open:has(.submenu-link.active) {
        .title-box {
          background-color: transparent;
          color: $grey-1;
          // border-radius: 56px 0 0 56px;
          .icon {
            svg {
              path {
                fill: $grey-1;
              }
            }
          }
          .arrow {
            svg {
              path {
                fill: $grey-1;
              }
            }
          }
        }
      }
    }
  }

  &-main-content {
    width: calc(100vw - $sidenav-width);
    background-color: $light-blue-1;
    &-top-bar {
      padding: 16px 30px;
      border-bottom: 1px solid $grey-4;
      background: $white;
      position: sticky;
      top: 0;
      z-index: 100;

      &-notification {
        img {
          width: 32px;
        }
      }
      &-profile {
        img {
          width: 32px;
        }
        p {
          color: $grey-2;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
      }

      .hamburger {
        cursor: pointer;
        display: inline-block;
        position: relative;
        z-index: 20;
        background-color: transparent;
        border: none;
        span {
          background-color: #848484;
          display: block;
          height: 0.133em;
          margin-bottom: 0.28em;
          position: relative;
          top: 0;
          transition: all 0.3s ease-in-out;

          &:nth-of-type(1) {
            width: 1.25em;
            margin-left: 2px;
          }
          &:nth-of-type(2) {
            width: 1.6em;
            height: 2px;
          }
          &:nth-of-type(3) {
            width: 1.3em;
            margin-left: -3px;
            height: 2px;
            margin-bottom: 0;
          }
        }
      }

      &-title-box {
        h2 {
          color: $grey-5;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }

    &-body {
      padding: 20px;
    }
  }
}

.page-header-box {
  h3 {
    color: $grey-6;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
    letter-spacing: -0.3px;
  }
  p {
    color: $grey-1;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    span.special {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}

.mobile-nav-accordion {
  border-bottom: 0.5px solid rgba(240, 240, 240, 0.4);
  button {
    outline: none;
    font-weight: 700;
  }
  &-panel {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding-bottom: 16px;
    transition: max-height 1.6s ease-in-out, opacity 2s;
  }
  &-panel.active {
    opacity: 1;
    max-height: max-content;
  }
}
@media (max-width: 991px) {
  .dashboard {
    padding: 0;
    margin: 0;
    &-side-nav {
      position: fixed;
      z-index: 1000;
      width: 100vw;
      transform: translateX(-100%);
      transition: all 0.3s ease-in-out;
      &-logo-box {
        padding: 20px 36px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        button {
          background-color: transparent;
          text-decoration: underline;
          border: none;
          font-weight: 700;
          color: $grey-1;
        }
      }
      &-list {
        padding-bottom: 20px;
        ul {
          list-style-type: none;
          li {
            margin-bottom: 10px;
            padding: 10px 25px;
            a {
              display: flex;
              align-items: center;
              gap: 18px;
              text-decoration: none;
              color: $grey-1;
              .icon {
                width: 14px;
              }
              .text {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.2px;
              }
            }
          }
        }
      }
    }

    &-side-nav.active {
      transform: translateX(0);
    }

    &-main-content {
      width: 100vw;
      &-top-bar {
        padding: 16px 18px;
        border-bottom: 1px solid $grey-4;
        background: $white;

        &-notification {
          img {
            width: 32px;
          }
        }
        &-profile {
          img {
            width: 32px;
          }
          p {
            color: $grey-2;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
        }

        .hamburger {
          cursor: pointer;
          display: inline-block;
          position: relative;
          z-index: 20;
          background-color: transparent;
          border: none;
          span {
            background-color: #848484;
            display: block;
            height: 0.133em;
            margin-bottom: 0.28em;
            position: relative;
            top: 0;
            transition: all 0.3s ease-in-out;

            &:nth-of-type(1) {
              width: 1.25em;
              margin-left: 2px;
            }
            &:nth-of-type(2) {
              width: 1.6em;
              height: 2px;
            }
            &:nth-of-type(3) {
              width: 1.3em;
              margin-left: -3px;
              height: 2px;
              margin-bottom: 0;
            }
          }
        }

        &-title-box {
          h2 {
            color: $grey-5;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
          }
        }
      }

      // &-body {
      // //   padding: 20px;
      // }
    }
  }

  .page-header-box {
    h3 {
      color: $grey-6;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 8px;
      letter-spacing: -0.3px;
    }
    p {
      color: $grey-1;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.1px;
      span.special {
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
      }
    }
  }
}

.tx-blue {
  color: $main-blue;
}

.rs-btn {
  background-color: transparent !important;
}

