.dashboard-index {
  padding: 20px 10px;
}
.dashboard-index-card {
  min-height: 300px;
  border-radius: 8px;
  background: #eff6ff;
}
.dashboard-index-card h2 {
  color: #1e293b;
}
.dashboard-index-card p {
  color: #0f172a;
}
.dashboard-index-card h6 {
  color: #3b82f6;
}

.payroll-bread-crumb {
  font-size: 14px;
}
.payroll-bread-crumb-active p span {
  color: #2563eb;
}
.payroll-bread-crumb-active p span svg path {
  fill: #2563eb;
}
.payroll-bread-crumb-arrow svg {
  rotate: 90deg;
  fill: #64748b;
  width: 8px;
}
.payroll-setup button {
  border-radius: 2px;
  border: 1px solid #93c5fd;
  background: #eff6ff;
}
.payroll-tabs-list li {
  padding: 3px 0;
  margin-right: 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in-out;
}
.payroll-tabs-list li.active {
  border-bottom: 2px solid #2563eb;
  background-color: transparent !important;
  color: #2563eb !important;
}

.payroll-overlay {
  content: "";
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  inset: 0;
  z-index: 10000;
}
.payroll-overlay-button {
  background-color: #f8fafc;
  width: -moz-max-content;
  width: max-content;
  border-radius: 3px;
}
.payroll-overlay-button button {
  border-radius: 2px;
  border: 1px solid #93c5fd;
  background: #eff6ff;
  width: -moz-max-content;
  width: max-content;
}
.payroll-overlay-info {
  border-radius: 4px;
}
.payroll-overlay-info button.set {
  box-shadow: 0px 4px 6px 0px rgba(37, 99, 235, 0.1);
}

.payroll-setup-modal-form label {
  color: #1e293b;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
}
.payroll-setup-modal-form select {
  width: 100%;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  border: 1.2px solid #cbd5e1;
  color: #475569;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.payroll-setup-modal-form p.info {
  color: #77777a;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.payroll-setup-modal-overtime-policy-toggle .toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}
.payroll-modal-setup-overtime-policy-toggle-disable .toggle-disable {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}
.payroll-setup-modal-overtime-policy-toggle .toggle input {
  display: none;
}
.payroll-modal-setup-overtime-policy-toggle-disable .toggle-disable input {
  display: none;
}
.payroll-setup-modal-overtime-policy-toggle .toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #ccc; */
  background-color: #d8d8d8;
  border-radius: 28px;
  transition: 0.4s;
}
.payroll-modal-setup-overtime-policy-toggle-disable .toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #ccc; */
  background-color: #93c5fd;
  border-radius: 28px;
  transition: 0.4s;
}
.payroll-setup-modal-overtime-policy-toggle .toggle-slider:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: 0.4s;
}
.payroll-modal-setup-overtime-policy-toggle-disable .toggle-slider:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: 0.4s;
}
.payroll-setup-modal-overtime-policy-toggle
  .toggle
  input:checked
  + .toggle-slider {
  background-color: #2563eb;
}
.payroll-modal-setup-overtime-policy-toggle-disable
  .toggle-dissable
  input:checked
  + .toggle-slider {
  background-color: #93c5fd;
}
.payroll-setup-modal-overtime-policy-toggle
  .toggle
  input:checked
  + .toggle-slider:before {
  transform: translateX(22px);
}
.payroll-modal-setup-overtime-policy-toggle-disable
  .toggle-disable
  input:checked
  + .toggle-slider:before {
  transform: translateX(22px);
}

.payroll-divider {
  height: 1px;
  width: 100%;
  background-color: #e2e8f0;
}

.dashboard {
  padding: 0;
  margin: 0;
}
.dashboard-side-nav {
  min-width: 260px;
  background-color: #fff;
  border-right: 1px solid #f1f5f9;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: 0;
  left: 0;
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #bfdbfe #f1f1f1;
}
.dashboard-side-nav::-webkit-scrollbar {
  width: 3px;
}
.dashboard-side-nav::-webkit-scrollbar-thumb {
  background: #bfdbfe;
  border-radius: 5px;
}
.dashboard-side-nav::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dashboard-side-nav-logo-box {
  padding: 20px 36px;
  margin-bottom: 20px;
}
.dashboard-side-nav-list {
  padding-bottom: 20px;
}
.dashboard-side-nav-list ul {
  list-style-type: none;
}
.dashboard-side-nav-list ul li {
  margin-bottom: 10px;
}
.dashboard-side-nav-list ul li a,
.dashboard-side-nav-list ul li .title-box {
  padding: 10px 25px;
  display: flex;
  align-items: center;
  gap: 18px;
  text-decoration: none;
  color: #475569;
  cursor: pointer;
}
.dashboard-side-nav-list ul li a .icon,
.dashboard-side-nav-list ul li .title-box .icon {
  width: 18px;
}
.dashboard-side-nav-list ul li a .text,
.dashboard-side-nav-list ul li .title-box .text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
}
.dashboard-side-nav-list ul li a.active {
  background-color: #2563eb;
  color: #fff;
  border-radius: 56px 0 0 56px;
}
.dashboard-side-nav-list ul li a.active .icon svg path {
  fill: #fff;
}
.dashboard-side-nav-list-item .title-box .arrow {
  rotate: 180deg;
  transition: all 0.2s ease-in-out;
}
.dashboard-side-nav-list-item .title-box .arrow svg {
  width: 10px;
}
.dashboard-side-nav-list-item .content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}
.dashboard-side-nav-list-item.open .title-box {
  background-color: transparent;
}
.dashboard-side-nav-list-item.open .title-box .arrow {
  rotate: 0deg;
}
.dashboard-side-nav-list-item.open .content {
  height: auto;
}
.dashboard-side-nav-list-item:has(.submenu-link.active) .title-box {
  background-color: #2563eb;
  color: #fff;
  border-radius: 56px 0 0 56px;
}
.dashboard-side-nav-list-item:has(.submenu-link.active)
  .title-box
  .icon
  svg
  path {
  fill: #fff;
}
.dashboard-side-nav-list-item:has(.submenu-link.active)
  .title-box
  .arrow
  svg
  path {
  fill: #fff;
}
.dashboard-side-nav-list-item.open:has(.submenu-link.active) .title-box {
  background-color: transparent;
  color: #475569;
}
.dashboard-side-nav-list-item.open:has(.submenu-link.active)
  .title-box
  .icon
  svg
  path {
  fill: #475569;
}
.dashboard-side-nav-list-item.open:has(.submenu-link.active)
  .title-box
  .arrow
  svg
  path {
  fill: #475569;
}
.dashboard-main-content {
  /* width: calc(100vw - 240px); */
  background-color: #f8fafc;
}
.dashboard-main-content-top-bar {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 100;
}
.dashboard-main-content-top-bar-notification img {
  width: 32px;
}
.dashboard-main-content-top-bar-profile img {
  width: 32px;
}
.dashboard-main-content-top-bar-profile p {
  color: #64748b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.dashboard-main-content-top-bar .hamburger {
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 20;
  background-color: transparent;
  border: none;
}
.dashboard-main-content-top-bar .hamburger span {
  background-color: #848484;
  display: block;
  height: 0.133em;
  margin-bottom: 0.28em;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
}
.dashboard-main-content-top-bar .hamburger span:nth-of-type(1) {
  width: 1.25em;
  margin-left: 2px;
}
.dashboard-main-content-top-bar .hamburger span:nth-of-type(2) {
  width: 1.6em;
  height: 2px;
}
.dashboard-main-content-top-bar .hamburger span:nth-of-type(3) {
  width: 1.3em;
  margin-left: -3px;
  height: 2px;
  margin-bottom: 0;
}
.dashboard-main-content-top-bar-title-box h2 {
  color: #334155;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.page-header-box h3 {
  color: #1e293b;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
  letter-spacing: -0.3px;
}
.page-header-box p {
  color: #475569;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.page-header-box p span.special {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.mobile-nav-accordion {
  border-bottom: 0.5px solid rgba(240, 240, 240, 0.4);
}
.mobile-nav-accordion button {
  outline: none;
  font-weight: 700;
}
.mobile-nav-accordion-panel {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding-bottom: 16px;
  transition: max-height 1.6s ease-in-out, opacity 2s;
}
.mobile-nav-accordion-panel.active {
  opacity: 1;
  max-height: -moz-max-content;
  max-height: max-content;
}

@media (max-width: 991px) {
  .dashboard {
    padding: 0;
    margin: 0;
  }
  .dashboard-side-nav {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
  }
  .dashboard-side-nav-logo-box {
    padding: 20px 36px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  .dashboard-side-nav-logo-box button {
    background-color: transparent;
    text-decoration: underline;
    border: none;
    font-weight: 700;
    color: #475569;
  }
  .dashboard-side-nav-list {
    padding-bottom: 20px;
  }
  .dashboard-side-nav-list ul {
    list-style-type: none;
  }
  .dashboard-side-nav-list ul li {
    margin-bottom: 10px;
    padding: 10px 25px;
  }
  .dashboard-side-nav-list ul li a {
    display: flex;
    align-items: center;
    gap: 18px;
    text-decoration: none;
    color: #475569;
  }
  .dashboard-side-nav-list ul li a .icon {
    width: 14px;
  }
  .dashboard-side-nav-list ul li a .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
  .dashboard-side-nav.active {
    transform: translateX(0);
  }
  .dashboard-main-content {
    width: 100vw;
  }
  .dashboard-main-content-top-bar {
    background: #fff;
  }
  .dashboard-main-content-top-bar-notification img {
    width: 32px;
  }
  .dashboard-main-content-top-bar-profile img {
    width: 32px;
  }
  .dashboard-main-content-top-bar-profile p {
    color: #64748b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  .dashboard-main-content-top-bar .hamburger {
    cursor: pointer;
    display: inline-block;
    position: relative;
    z-index: 20;
    background-color: transparent;
    border: none;
  }
  .dashboard-main-content-top-bar .hamburger span {
    background-color: #848484;
    display: block;
    height: 0.133em;
    margin-bottom: 0.28em;
    position: relative;
    top: 0;
    transition: all 0.3s ease-in-out;
  }
  .dashboard-main-content-top-bar .hamburger span:nth-of-type(1) {
    width: 1.25em;
    margin-left: 2px;
  }
  .dashboard-main-content-top-bar .hamburger span:nth-of-type(2) {
    width: 1.6em;
    height: 2px;
  }
  .dashboard-main-content-top-bar .hamburger span:nth-of-type(3) {
    width: 1.3em;
    margin-left: -3px;
    height: 2px;
    margin-bottom: 0;
  }
  .dashboard-main-content-top-bar-title-box h2 {
    color: #334155;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .page-header-box h3 {
    color: #1e293b;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
    letter-spacing: -0.3px;
  }
  .page-header-box p {
    color: #475569;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
  .page-header-box p span.special {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }
}
.tx-blue {
  color: #2563eb;
}

.rs-btn {
  background-color: transparent !important;
} /*# sourceMappingURL=dashboard.css.map */
