// COLORS
$main-green: #059669;
$main-yellow: #d97706;
$white: #fff;
$main-blue: #2563eb;
$light-blue-1: #f8fafc;
$light-blue-2: #eff6ff;
$light-blue-3: #bfdbfe;
$black: #000;

$grey-1: #475569;
$grey-2: #64748b;
$grey-3: #cbd5e1;
$grey-4: #f1f5f9;
$grey-5: #334155;
$grey-6: #1e293b;
$grey-7: #e2e8f0;
$grey-8: #0f172a;
$grey-9: #919094;
$grey-10: #77777a;
$grey-transparent: #f8fafc;
$yellow-transparent: #fffbeb;
$blue-transparent: #eff6ff;
$green-transparent: #ecfdf5;

// FONTS
$base-font: "Mulish", sans-serif;

// DIMENSIONS
$sidenav-width: 260px;
