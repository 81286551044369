.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  padding: 24px;
  outline: none;
  color: blue;
  border-radius: 8px;
  border: 1px dashed #cbd5e1;
  background: #fff;
}

.custom-upload-label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  // border: 2px dashed #ccc;
  border-radius: 8px;
}

.upload-icon img {
  width: 24px;
  height: 24px;
}

.upload-text {
  text-align: center;
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #919094;
    line-height: 142.857%;
  }

  p {
    color: green;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.5px;
    color: #77777a;
    text-align: center;
    font-size: 11px;
  }
}

.selected-file {
  margin-top: 20px;
  text-align: center;
}

/* Optional: Style the selected file text */
#file-name {
  font-weight: bold;
  color: #333;
}
